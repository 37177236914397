import React from 'react';
import { getAnchoredPosition } from '@primer/behaviors';
import { useProvidedRefOrCreate } from './useProvidedRefOrCreate.js';
import { useResizeObserver } from './useResizeObserver.js';
import useIsomorphicLayoutEffect from '../utils/useIsomorphicLayoutEffect.js';

/**
 * Calculates the top and left values for an absolutely-positioned floating element
 * to be anchored to some anchor element. Returns refs for the floating element
 * and the anchor element, along with the position.
 * @param settings Settings for calculating the anchored position.
 * @param dependencies Dependencies to determine when to re-calculate the position.
 * @returns An object of {top: number, left: number} to absolutely-position the
 * floating element.
 */
function useAnchoredPosition(settings, dependencies = []) {
  const floatingElementRef = useProvidedRefOrCreate(settings === null || settings === undefined ? undefined : settings.floatingElementRef);
  const anchorElementRef = useProvidedRefOrCreate(settings === null || settings === undefined ? undefined : settings.anchorElementRef);
  const [position, setPosition] = React.useState(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPrevHeight] = React.useState(undefined);
  const topPositionChanged = (prevPosition, newPosition) => {
    return prevPosition && ['outside-top', 'inside-top'].includes(prevPosition.anchorSide) && (
    // either the anchor changed or the element is trying to shrink in height
    prevPosition.anchorSide !== newPosition.anchorSide || prevPosition.top < newPosition.top);
  };
  const updateElementHeight = () => {
    let heightUpdated = false;
    setPrevHeight(prevHeight => {
      var _floatingElementRef$c, _floatingElementRef$c2;
      // if the element is trying to shrink in height, restore to old height to prevent it from jumping
      if (prevHeight && prevHeight > ((_floatingElementRef$c = (_floatingElementRef$c2 = floatingElementRef.current) === null || _floatingElementRef$c2 === undefined ? undefined : _floatingElementRef$c2.clientHeight) !== null && _floatingElementRef$c !== undefined ? _floatingElementRef$c : 0)) {
        requestAnimationFrame(() => {
          floatingElementRef.current.style.height = `${prevHeight}px`;
        });
        heightUpdated = true;
      }
      return prevHeight;
    });
    return heightUpdated;
  };
  const updatePosition = React.useCallback(() => {
    var _floatingElementRef$c5;
    if (floatingElementRef.current instanceof Element && anchorElementRef.current instanceof Element) {
      const newPosition = getAnchoredPosition(floatingElementRef.current, anchorElementRef.current, settings);
      setPosition(prev => {
        if (settings !== null && settings !== undefined && settings.pinPosition && topPositionChanged(prev, newPosition)) {
          var _anchorElementRef$cur, _anchorElementRef$cur2, _floatingElementRef$c3, _floatingElementRef$c4;
          const anchorTop = (_anchorElementRef$cur = (_anchorElementRef$cur2 = anchorElementRef.current) === null || _anchorElementRef$cur2 === undefined ? undefined : _anchorElementRef$cur2.getBoundingClientRect().top) !== null && _anchorElementRef$cur !== undefined ? _anchorElementRef$cur : 0;
          const elementStillFitsOnTop = anchorTop > ((_floatingElementRef$c3 = (_floatingElementRef$c4 = floatingElementRef.current) === null || _floatingElementRef$c4 === undefined ? undefined : _floatingElementRef$c4.clientHeight) !== null && _floatingElementRef$c3 !== undefined ? _floatingElementRef$c3 : 0);
          if (elementStillFitsOnTop && updateElementHeight()) {
            return prev;
          }
        }
        return newPosition;
      });
    } else {
      setPosition(undefined);
    }
    setPrevHeight((_floatingElementRef$c5 = floatingElementRef.current) === null || _floatingElementRef$c5 === undefined ? undefined : _floatingElementRef$c5.clientHeight);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [floatingElementRef, anchorElementRef, ...dependencies]);
  useIsomorphicLayoutEffect(updatePosition, [updatePosition]);
  useResizeObserver(updatePosition); // watches for changes in window size
  useResizeObserver(updatePosition, floatingElementRef); // watches for changes in floating element size

  return {
    floatingElementRef,
    anchorElementRef,
    position
  };
}

export { useAnchoredPosition };
