import { useEffect } from 'react';
import { iterateFocusableElements } from '@primer/behaviors/utils';

function useOpenAndCloseFocus({
  initialFocusRef,
  returnFocusRef,
  containerRef,
  preventFocusOnOpen
}) {
  useEffect(() => {
    // If focus should be applied on open, apply focus to correct element,
    // either the initialFocusRef if given, otherwise the first focusable element
    if (!preventFocusOnOpen) {
      if (initialFocusRef && initialFocusRef.current) {
        initialFocusRef.current.focus();
      } else if (containerRef.current) {
        const firstItem = iterateFocusableElements(containerRef.current).next().value;
        firstItem === null || firstItem === undefined ? undefined : firstItem.focus();
      }
    }

    // If returnFocusRef element is rendered, apply focus
    const returnFocusRefCurrent = returnFocusRef.current;
    return function () {
      returnFocusRefCurrent === null || returnFocusRefCurrent === undefined ? undefined : returnFocusRefCurrent.focus();
    };
  }, [initialFocusRef, returnFocusRef, containerRef, preventFocusOnOpen]);
}

export { useOpenAndCloseFocus };
