import { COMMON, TYPOGRAPHY } from '../constants.js';

const COMMON_PROP_NAMES = new Set(Object.keys(COMMON));
const TYPOGRAPHY_PROP_NAMES = new Set(Object.keys(TYPOGRAPHY));
const includesSystemProps = props => {
  if (props.sx) {
    return true;
  }
  return Object.keys(props).some(prop => {
    return TYPOGRAPHY_PROP_NAMES.has(prop) || COMMON_PROP_NAMES.has(prop);
  });
};
const getTypographyAndCommonProps = props => {
  let typographyAndCommonProps = {};
  for (const prop of Object.keys(props)) {
    if (TYPOGRAPHY_PROP_NAMES.has(prop) || COMMON_PROP_NAMES.has(prop)) {
      const p = prop;
      typographyAndCommonProps = {
        ...typographyAndCommonProps,
        [p]: props[p]
      };
    }
  }
  return typographyAndCommonProps;
};

export { getTypographyAndCommonProps, includesSystemProps };
