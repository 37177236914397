import { useState, useRef, useCallback, useEffect } from 'react';

function useDetails({
  ref,
  closeOnOutsideClick,
  defaultOpen,
  onClickOutside
}) {
  const [open, setOpen] = useState(defaultOpen);
  const backupRef = useRef(null);
  const customRef = ref !== null && ref !== undefined ? ref : backupRef;
  const onClickOutsideInternal = useCallback(event => {
    const {
      current
    } = customRef;
    const eventTarget = event.target;
    const closest = eventTarget.closest('details');
    if (closest !== current) {
      onClickOutside && onClickOutside(event);
      if (!event.defaultPrevented) {
        setOpen(false);
      }
    }
  }, [customRef, setOpen, onClickOutside]);

  // handles the overlay behavior - closing the menu when clicking outside of it
  useEffect(() => {
    if (open && closeOnOutsideClick) {
      document.addEventListener('click', onClickOutsideInternal);
      return () => {
        document.removeEventListener('click', onClickOutsideInternal);
      };
    }
  }, [open, closeOnOutsideClick, onClickOutsideInternal]);
  const handleToggle = e => {
    if (!e.defaultPrevented) {
      const eventTarget = e.target;
      setOpen(eventTarget.open);
    }
  };
  const getDetailsProps = () => {
    return {
      onToggle: handleToggle,
      open,
      ref: customRef
    };
  };
  return {
    open,
    setOpen,
    getDetailsProps
  };
}

export { useDetails as default };
